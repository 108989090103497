'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.dispatch.factory:IntercadMessages

 # @description

###
angular
  .module 'mundoAdmin.dispatch'
  .factory 'IntercadMessages', [
    'Restangular'
    (Restangular) ->
      Restangular.service('lpa/intercad/messages')
  ]
